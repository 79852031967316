
* {
  font-family: "Montserrat";
  font-size: 12px;
}



body{
  background: #f4f7fa !important;
  
}

.maplabel{
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail{
  color: tomato;
}

.fa-file-word.file-thumbnail{
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail{
  color: #198754;
}


footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
}

footer a{
  text-decoration: none;
}

.header{
  background: none;
}
.header, .header a{
  color: #1a293b
}

.sidebar-header{
  color:#fff;
}

.btn-floating{
  border-radius: 50% !important;
}

.field-label{
  font-size: smaller;
}
.field-data{
  border-bottom: 1px solid #ccc;
  
}

.related-list-table a{
  text-decoration: none;
}

.btn-primary, .btn-info{
  background-color: #5f65f0 !important;
  color: #fff !important;
  border-color: #5f65f0 !important;
  font-weight: 600 !important;
}

.login-form{
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top:5rem;
}

.login-form i{
  color: #5f65f0;

}
.login-section{
  border-left: 1px solid #ccc;
}

.login-form img{
  width: 100%;
}

.login-form .vr{
  border-right: 1px solid #ccc;
}
.data-table a{
  text-decoration: none;
  font-weight: 600;
}

.data-table th{
  background-color: #1a293b !important;
    color: #fff;
    font-size: .9rem;
    border: 1px solid #1a293b;
}

.data-table{
  border: 1px solid #1a293b;
  border-radius: 5px;
}

.view-form label{
  font-size: smaller;
  display: block;
  margin-top: 1rem;
  text-transform: uppercase;
}

.view-form-header{
  background-color: #1a293b;
  color: #fff;
  padding: .5rem;
  border-radius: 5px 5px 0 0;
}


.form-detail, .form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255,255,255,.75);
}

.view-form span{
  display: block;
  border-bottom: 1px solid #ccc;
  
}

.fl{
  padding-left: 0px !important;
}
.fr{
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  

}
input[type=number].no-spinner::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event{
  cursor: pointer;
}
table.fc-col-header{
  background-color:#1a293b;
}

table.fc-col-header a{
  color: #fff !important;
}
th.fc-col-header-cell{
  padding: .5rem 0 .5rem 0 !important;
  /*border: 1px solid #1a293b !important;*/
}

.shift {
  padding-left: 60px;
}
.assessments{
  margin-left: 80px;
  margin-right: 80px;
}


.listTable{
  margin-top: 6rem!important;
}
.section-header {
  background-color: #ccc;
  padding-top: .5rem ;
  padding-bottom: .5rem;
}


.listGroupStudents {
  padding-top: 45px;
}

.listGroupStudents{
  padding-top: 50px;
}

.numberCircle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 1px;
  display: inline-block !important;
  background: #ffc107;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 10px  ;
}

.form-view-radio label{
  font-size: smaller;
  display: block;
  margin-top:  0rem !important;
 
  }

.form-view-radio2 label{
  font-size: smaller;
  display: block;
  margin-top: 0rem !important;
 
  }
/*   
  modal .modal-content {
    background-color: #F8D7DA;

  }
  */

  .btn-outline-primary{
    font-weight: 600 !important;
  }

  .btn-link{
    text-decoration: none !important;
  }
  
  .toast-header .btn-close{
    background: none;
  }

  .fa-stack.small { font-size: 2em; }
    i { vertical-align: middle; }
  
  
input.form-control[required]{
  border-left: 4px solid red;
}

input.form-control[required]{
  border-left: 4px solid red;
}

textarea.form-control[required]{
  border-left: 4px solid red;
}

.form-select[required]{
  border-left: 4px solid red;
}

.login-form input[required] {
  border-left: 1px solid #ccc;
}
.childtable th{
  background-color: #6a6a8d !important;
  border: 1px solid #6a6a8d;

}

.listItem{
  background-color: #6a6a8d !important;
 color: #fff;
  border: 1px solid #6a6a8d;
}
.listGroup {
  margin-top: 25px;
}

.login-form input[required] {
  border-left: 1px solid #ccc;
}

/* .mymodal .modal-content {
  background: #fff;
} */
    
.img-thumbnail{
  max-height: 150px;
  max-width: 100px !important;
}

.error-alert{
  width: 55%;
  margin: auto;
}
.ques-label{
  text-transform: capitalize;
}

.multi-staff span{
  border: none !important;
}

.multi-staff .dropdown-heading{
  height: 30px !important;
  border-left: 4px solid #dc3545;
}
.multi-staff .select-item {
  margin: 0 !important;
  padding: .5rem !important;
}


.blue{
  background: #2A569A;
  color: #fff;
  padding: .5rem;
  display: flex;
  align-items: baseline;
  border:none; 
}
.alertBPL {
  width: 105%;
  margin-left: 5px;
}

.form-check-input[type=checkbox]{
  margin-top: 2px;
  margin-left: 4px;
}



.disabled label {
  background-color: #ed2121; /* change the background color to gray */
  color: #a9adb2; /* change the text color to gray */
}

/* .td{
  font-size: medium;
} */


.react-datepicker-wrapper {
  width: '80%';
}